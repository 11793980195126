<template>
	<div class="mt-3">
		<b-form @submit.prevent="onSubmit">
			<div class="row">
				<div class="col-md-8">
					<div class="row text-black-50 text-right">
						<div class="col-sm-8">
							<div v-if="isBusy" class="text-right">
								Please wait...<b-spinner small class="ml-1"></b-spinner>
							</div>
						</div>
					</div>
					<b-form-group
						id="input-group-1"
						label="FM AWB"
						label-size="sm"
					>
						<b-form-input
						v-model="fm_awb"
						type="text"
						placeholder="FM AWB"
						required
						></b-form-input>
						<b-form-invalid-feedback :state="validation">
							Please enter FM AWB field.
						</b-form-invalid-feedback>
						<small v-if="fm_dims">
							({{fm_dims}})
						</small>
					</b-form-group>
					<div class="row">
						<div class="col-md-4">
							<b-form-group
								id="input-group-1"
								label="LM AWB"
								label-size="sm"
							>
								<b-form-input
								v-model="item.lm_awb"
								type="text"
								placeholder="LM AWB"
								size="sm"
								required
								></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-2 col-6">
							<b-form-group
								id="input-group-1"
								label="Length (cm)"
								label-size="sm"
							>
								<b-form-input
									v-model="length"
									placeholder="Length"
									type="number"
									size="sm"
									min="1"
									step="0.01"
									required
									></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-2 col-6">
							<b-form-group
								id="input-group-1"
								label="Width (cm)"
								label-size="sm"
							>
								<b-form-input
									v-model="item.width"
									placeholder="Width"
									type="number"
									step="0.01"
									size="sm"
									min="1"
									required
									></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-2 col-6">
							<b-form-group
								id="input-group-1"
								label="Height (cm)"
								label-size="sm"
							>
								<b-form-input
									v-model="item.height"
									placeholder="Height"
									type="number"
									step="0.01"
									size="sm"
									min="1"
									required
									></b-form-input>
							</b-form-group>
						</div>
						<div class="col-md-2 col-6">
							<b-form-group
								id="input-group-1"
								label="Weight (kg)"
								label-size="sm"
							>
								<b-form-input
									v-model="item.weight"
									placeholder="Weight"
									type="number"
									step="0.001"
									size="sm"
									min="0.001"
									required
									></b-form-input>
							</b-form-group>
						</div>
					</div>
					<b-button type="submit" variant="success">Submit</b-button>
				</div>
			</div>

			<div v-if="bags && bags.length" class="row">
				<div class="col-sm-12 mt-4">
					<h4>Added Packages</h4>
					<b-table striped hover :items="bags" :fields="fields">
						<template v-slot:cell(h_awb)="data">
							{{data.item.h_awb}}
							<span @click="printBarcode(data.item)" class="btn-sm btn-primary float-right pointer ml-2" title="Print Barcode" v-b-tooltip.hover><b-icon-printer></b-icon-printer></span>
						</template>
					</b-table>
				</div>
			</div>

		</b-form>

		<!-- Print Bagcode -->
		<div style="display:none">
			<span id="capture">
				<VueBarcode :value="print_data.h_awb" :height="75">
					Failed to generate barcode.
				</VueBarcode>
			</span>
		</div>

	</div>
</template>

<script>

import VueBarcode from 'vue-barcode';
export default {
	name: 'ManualInScan',
	title: 'Manual InScan',
	components: {
		VueBarcode
	},
	data() {
		return {
			isBusy: false,
			connect: false,
			item: {},
			total: null,
			fm_dims: this.$store.getters.global.fm_dims || '',
			length: '',
			dataApi: 'http://localhost:8080',
			livedata: {weight:'', weight_status: false, camera_status: false},
			bags: [],
			print_data: {},
			fm_awb: this.$store.getters.global.fm_awb || '',
			fmDisable: false,
			validation: undefined,
			fields: [
				{key: 'fm_awb', label: 'FM AWB'},
				{key: 'lm_awb', label: 'LM AWB'},
				{key: 'length', label: 'Length'},
				{key: 'height', label: 'Height'},
				{key: 'width', label: 'Width'},
				{key: 'weight', label: 'Weight'},
				{key: 'h_awb', label: 'HAWB'},
			]
		}
	},
	created() {
		if(this.fm_awb) {
			this.fmDisable = true
			this.getBags()
		}
	},
	methods: {
		async getBags() {
			if(this.fm_awb) {
				this.isBusy = true
				const res = await this.axios.get(`/inscan/${this.fm_awb}`)
				if(res.data.success) {
					this.bags = res.data.data
					this.total = res.data.count
				}
				this.isBusy = false
			}
		},
		async onSubmit() {
			let form = this.item
			form.length = this.length
			form.fm_awb = this.fm_awb
			try {
				this.isBusy = true;
				let carrier_id = 0
				const carrierIdRes = await this.axios.post('/carrier-id', {lm_awb: this.item.lm_awb})
				if(carrierIdRes.data && carrierIdRes.data.success)
				carrier_id = carrierIdRes.data.carrier_id;

				if(this.item.lm_awb.length > 28 && carrier_id != 2 && carrier_id != 7 && carrier_id != 20) {
					this.item.lm_awb = this.item.lm_awb.substring(8)
				}
			    else if(carrier_id == 20) {
                    this.item.lm_awb = this.item.lm_awb.substring(8, 22)
                }

				const res = await this.axios.post('/inscan', form)
				if(res.data.success) {
					this.item.h_awb = res.data.h_awb
					this.bags.unshift(this.item)
					
					this.item = {}
					this.length = ''
					this.printBarcode(form)
					this.$bvToast.toast(res.data.message, {
						title: `Added!`,
						variant: 'success',
						solid: true
					});
				} else {
					this.popToast('Failed!', res.data.message, 'danger')
				}
				this.isBusy = false
			} catch (err) {
				this.isBusy = false
				console.log(err)
			}
			this.isBusy = false
		},

		async printBarcode(bag) {
			this.print_data = bag
			console.log(bag)
			setTimeout(() => {
				const contents = document.getElementById('capture').innerHTML;
				const w=window.open('','','left=0,bottom=0,width=100,height=1,toolbar=0,scrollbars=0,status=0');
				w.document.write(contents);
				w.document.close();
				w.focus();
				w.print();
				w.close();
			}, 500);
		},
	}
}
</script>

<style scoped>
	tbody tr:first-child input {
		margin-bottom: 8px;
	}
	.circle {
		width: 15px;height: 15px;border-radius: 50%; background:red; display: inline-block;
	}
</style>